import styled from 'styled-components/macro';
import { Spinner } from 'react-bootstrap';
import { DISPLAY_FLEX_COLUMN_DEFAULT } from '~/style/constants';
import { BootstrapButton } from '~/components/ui/BootstrapButton';

export const PaymentIframe = styled.iframe<{ isIframeLoading: boolean }>`
  width: 100%;
  height: 600px;
  border: 0;
  visibility: ${({ isIframeLoading }) => isIframeLoading && 'hidden'};
  position: relative;
`;

export const ModalBodyContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  gap: 35px;
`;

export const ModalNetworkText = styled.div`
  text-align: center;
`;

export const LoadingContainer = styled.div`
  ${DISPLAY_FLEX_COLUMN_DEFAULT}
  position: absolute;
`;

export const LoadingText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
`;

export const StyledSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
`;

export const ContinuePaymentButton = styled(BootstrapButton)`
  min-width: 200px;
`;
