import { FaXTwitter, FaYoutube } from 'react-icons/fa6';
import { FaTelegram, FaDiscord, FaInstagram } from 'react-icons/fa';
import { Container, SocialMediaLink } from './styles';

export function Help({ ...props }) {
  return (
    <Container {...props}>
      <SocialMediaLink rel="noopener noreferrer" href="https://t.me/officialhiglan" target="_blank">
        <FaTelegram size={30} />
        Telegram
      </SocialMediaLink>
      <SocialMediaLink rel="noopener noreferrer" href="https://discord.gg/RVdp6Ac6g4" target="_blank">
        <FaDiscord size={30} />
        Discord
      </SocialMediaLink>
      <SocialMediaLink rel="noopener noreferrer" href="https://twitter.com/officialhiglan" target="_blank">
        <FaXTwitter size={30} />
        Twitter
      </SocialMediaLink>
      <SocialMediaLink rel="noopener noreferrer" href="https://www.youtube.com/@higlan" target="_blank">
        <FaYoutube size={30} />
        YouTube
      </SocialMediaLink>
      <SocialMediaLink rel="noopener noreferrer" href="https://instagram.com/officialhiglan" target="_blank">
        <FaInstagram size={30} />
        Instagram
      </SocialMediaLink>
    </Container>
  );
}
