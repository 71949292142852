import { useState } from 'react';
import { FaCartArrowDown } from 'react-icons/fa';

import { Modal } from '~/components/ui/Modal';
import {
  ContinuePaymentButton,
  LoadingContainer,
  LoadingText,
  ModalBodyContainer,
  ModalNetworkText,
  PaymentIframe,
  StyledSpinner,
} from './styles';
import { BolderText } from '../styles';

interface IPaymentPage {
  handleHideModal: () => void;
  url: string;
}

const SHOULD_USE_IFRAME_PAYMENT = false;

export function PaymentModal({ handleHideModal, url }: IPaymentPage) {
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const handleIframeLoading = () => {
    setIsIframeLoading(false);
  };

  const handleContinueToPayment = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderBodyContent = () => {
    return (
      <ModalBodyContainer>
        <ModalNetworkText>
          Make sure to use one of the supported network: <BolderText>Base</BolderText>,{' '}
          <BolderText>Ethereum</BolderText> or <BolderText>Polygon</BolderText>.
        </ModalNetworkText>
        {SHOULD_USE_IFRAME_PAYMENT ? (
          <>
            {isIframeLoading ? (
              <LoadingContainer>
                <LoadingText>Loading...</LoadingText>
                <StyledSpinner animation="border" variant="black" />
              </LoadingContainer>
            ) : (
              <PaymentIframe src={url} onLoad={handleIframeLoading} isIframeLoading={isIframeLoading} />
            )}
          </>
        ) : (
          <ContinuePaymentButton variant="success" onClick={handleContinueToPayment}>
            <FaCartArrowDown />
            Continue to Payment
          </ContinuePaymentButton>
        )}
      </ModalBodyContainer>
    );
  };

  return (
    <Modal
      bodyContent={renderBodyContent()}
      title="ONCHAIN PAYMENT"
      show={true}
      onHide={handleHideModal}
      hasConfirmation={false}
      size="lg"
      submitButtonIcon={<FaCartArrowDown />}
    />
  );
}
